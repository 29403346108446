import api from '../api/thoth';

async function uploadAccountEntry(file, afiliation, type) {
    try {
        const formData = new FormData();
        formData.append('File', file);
        formData.append('affiliation', afiliation);
        formData.append('type', type);
        const { status } = await api.post('/accountEntry', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return status;
    } catch ({ response }) {
        return response.status;
    }
}

async function storeTree(file) {
    try {
        const formData = new FormData();
        formData.append('File', file);
        const { status } = await api.post('/storeTree', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

async function financialPendencies(file) {
    try {
        const formData = new FormData();
        formData.append('final-pendencies', file);
        const { status } = await api.post('financialPendencies', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

async function opportunitiesRegistration(file) {
    try {
        const formData = new FormData();
        formData.append('Opportunities', file);
        const { status } = await api.post('opportunitiesRegistration', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

async function redemptionContributions(file) {
    try {
        const formData = new FormData();
        formData.append('AnimaFile', file);
        const { status } = await api.post('contributions', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

export async function profitabilityCommercial(file, type) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const { status } = await api.post('commercial-profitability', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                company: type,
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

async function terminalRental(file) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const { status } = await api.post('/terminal-rental', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

export async function uploadAdamas(file) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const { status } = await api.post('/adamas-payment-register', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

export async function uploadBudget(file) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const { status } = await api.post('/budget', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

export {
    uploadAccountEntry,
    storeTree,
    financialPendencies,
    opportunitiesRegistration,
    redemptionContributions,
    terminalRental,
};
