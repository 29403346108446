import methods from '@/enums/methods';

const { default: Thoth } = require('../base/thoth');

class AccountEntryService extends Thoth {
    index() {
        return this.fetch({
            url: '/files/show',
        });
    }

    complete(id) {
        return this.fetch({
            url: '/files/complete',
            params: { id },
            method: methods.PUT,
        });
    }

    revoke(id) {
        return this.fetch({
            url: '/files/delete',
            params: { id },
            method: methods.DELETE,
        });
    }
}

export default new AccountEntryService();
