<template>
  <b-container v-if="renderPage">
    <b-overlay
      :show="showLoading"
      :opacity="1"
      variant="light"
      rounded="sm"
    >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <strong>Enviando Arquivo, Aguarde...</strong>
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
          <b-spinner
            type="grow"
            variant="primary"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
        </div>
      </template>
      <b-card>
        <validation-observer
          ref="uploadRede"
          #default="{ invalid }"
        >
          <b-form>

            <b-form-group
              label="Tipo"
              label-for="type"
            >
              <validation-provider
                #default="{ errors }"
                name="Tipo"
                rules="required"
              >
                <b-form-select
                  id="type"
                  v-model="type"
                  :options="[
                    { value: 0, text: 'Importação de arquivo'},
                    { value: 1, text: 'Correção de Lançamento'}
                  ]"
                  :state="errors.length > 0 ? false : null"
                />
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Afiliação"
              label-for="afiliation"
            >
              <validation-provider
                #default="{ errors }"
                name="Upload"
                rules="required"
              >
                <b-form-select
                  id="afiliation"
                  v-model="legalTaxNameSelected"
                  :options="legalTaxNamesOptions"
                  :state="errors.length > 0 ? false : null"
                />
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Arquivo"
              label-for="file"
            >
              <validation-provider
                #default="{ errors }"
                name="Upload"
                rules="required"
              >
                <b-form-file
                  id="file"
                  v-model="file"
                  placeholder="Arraste ou selecione um arquivo"
                  drop-placeholder="Arraste o arquivo aqui."
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              variant="primary"
              :disabled="invalid"
              class="btn-upload"
              @click="upload"
            >
              Enviar Arquivo
            </b-button>

            <b-button
              variant="primary"
              class="btn-upload ml-1"
              @click="() => {
                show()
                $refs.accountEntry.show()
              }"
            >
              Mostrar
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>

    <b-modal
      ref="accountEntry"
      hide-footer
      size="xl"
    >
      <b-table
        class="text-center"
        :items="items"
        :fields="[
          'id',
          { key: 'fileName', label: 'Nome do Arquivo'},
          { key: 'dateImported', label: 'Data da Importação'},
          { key: 'reffersTo', label: 'Referente a'},
          { key: 'actions', label: 'Ações'}
        ]"
      >
        <template #cell(dateImported)="data">
          {{ formatDate(data.item.dateImported) }}

          <!-- {{ data.item.dateImported }} -->
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click="revoke(data.item.id)">
              <feather-icon
                icon="XSquareIcon"
                size="18"
              />
              <span> Cancelar </span>
            </b-dropdown-item>

            <b-dropdown-item @click="complete(data.item.id)">
              <feather-icon
                icon="CheckSquareIcon"
                size="18"
              />
              <span> Completar </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-modal>
  </b-container>
</template>

<script>
import {
    BFormSelect,
    BTable,
    BFormFile,
    BFormGroup,
    BButton,
    BCard,
    BContainer,
    BForm,
    BSpinner,
    BOverlay,
    BDropdown, BDropdownItem,
    BIcon,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { required, email } from '@validations';
import { DateTime } from 'luxon';
import { uploadAccountEntry } from '@/service/file/others';

import AccountEntryService from '@/service/account-entry';

export default {
    components: {
        BFormFile,
        BFormSelect,
        BFormGroup,
        BButton,
        BCard,
        BContainer,
        BForm,
        BDropdown,
        BDropdownItem,
        BTable,
        BSpinner,
        BOverlay,
        BIcon,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            file: null,
            showLoading: false,
            renderPage: true,
            required,
            items: [],
            email,
            legalTaxNameSelected: null,
            type: 0,
            legalTaxNamesOptions: [
                { value: null, text: 'Selecione uma Afiliação' },
                { text: '1 - FIDC VIAINVEST', value: 'FIDC VIAINVEST' },
                { text: '2 - VIA FOMENTO', value: 'VIA FOMENTO' },
                { text: '3 - VSA', value: 'VSA' },
                { text: '6 - FIQ', value: 'FIQ' },
                { text: '7 - SOULPAY', value: 'SOULPAY' },
                { text: '9 - VSC', value: 'VSC' },
                { text: '10 - FIDC SOULPAY', value: 'FIDC SOULPAY' },
                { text: '31 - VIAINVEST II LP', value: 'VIAINVEST II LP' },
                { text: '301 - VIA PARTICIPAÇÕES', value: 'VIA PARTICIPACOES' },
                { text: '302 - VIA GESTAO', value: 'VIA GESTAO' },
                { text: '303 - VIA SECURITIZADORA', value: 'VIA SECURITIZADORA' },
            ],
        };
    },
    beforeRouteLeave(to, from, next) {
        this.renderPage = false;
        next();
    },
    computed: {
        isDisabledFile() {
            return this.selectedFileOption.title === 'Selecione o Tipo de Arquivo';
        },
    },
    methods: {
        formatDate(date) {
            return DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy HH:mm:ss');
        },
        async upload() {
            const confirmAnAction = await this.confirmAnAction('Deseja realmente enviar o arquivo?');
            if (!confirmAnAction) return;
            this.showLoading = true;

            const status = await uploadAccountEntry(this.file, this.legalTaxNameSelected, this.type);
            if (status === 201) {
                this.success();
            } else if (status === 422) {
                this.modalError('Padrão esperado do nome da Planilha: Planilha 1');
            } else {
                this.error();
            }
            this.showLoading = false;
        },

        async show() {
            const { data } = await AccountEntryService.index();

            this.items = data;
        },

        async complete(id) {
            const response = await this.confirmAnAction('Você deseja completar esse lançamento');

            if (!response) return;
            this.callLoading(true);
            const { status } = await AccountEntryService.complete(id);
            this.callLoading(false);

            await this.show();

            if (status === 200) {
                this.modalSuccess('Arquivo completado com sucesso');
            } else {
                this.modalError('Não foi possível completar o arquivo');
            }
        },

        async revoke(id) {
            const response = await this.confirmAnAction('Você deseja cancelar esse lançamento');

            if (!response) return;
            this.callLoading(true);
            const { status } = await AccountEntryService.revoke(id);
            this.callLoading(false);

            await this.show();

            if (status === 200) {
                this.modalSuccess('Arquivo cancelado com sucesso');
            } else {
                this.modalError('Não foi possível cancelar o arquivo');
            }
        },
        success() {
            this.$swal({
                icon: 'success',
                html: '<span>O Arquivo foi Enviado com Sucesso </span> ',
            });
        },
        error() {
            this.$swal({
                icon: 'error',
                html: '<span>Não foi possível enviar o arquivo</span> ',
            });
        },
    },
};
</script>
<style lang="scss">
  @import "@core/scss/vue/pages/upload-style.scss";
</style>
